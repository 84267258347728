import { ContactService } from './../service/contact.service';
import { HelperService } from './../service/helper.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

   qoutesForm:any= {
    name:"",
    phone:"",
    email:"",
    origin:"",
    destination:"",
    shipping_method:"",
    weight:"",
    depth:"",
    height:"",
    width:"",
    description:""
  };
lang

  constructor(private toastrService:ToastrService, private helper:HelperService, private contactService:ContactService ) { }

  ngOnInit(): void {
    this.helper.currentLang.subscribe(lang => this.lang = lang)
  }


  async send(){
    try{

      const formData = this.helper.jsonToFormData(this.qoutesForm);
      const res:any = await this.contactService.sendQuots(formData);
      if(res.status.code ==200){
        if(this.lang == "en"){
          this.toastrService.success('Message Has Been Sent Successfully.','Done');
        }else{
          this.toastrService.success('تم إرسال الرسالة بنجاح.','تم ');

        }        this.qoutesForm =  {
          name:"",
          phone:"",
          email:"",
          origin:"",
          destination:"",
          shipping_method:"",
          weight:"",
          depth:"",
          height:"",
          width:"",
          description:""
        };;

      }else{
        if(this.lang == "en"){
          this.toastrService.error(res.status.message,'Error');
        }else{
          this.toastrService.error(res.status.message,'خطأ');
        }
      }
    }catch(err){
      console.log(err);
    }
  }

}
