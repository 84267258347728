
import { HelperService } from './../service/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  serviceArr:any = {};
  lang:string = ''
  constructor(private helper:HelperService) { }

  ngOnInit(): void {
    this.helper.currentLang.subscribe(lang => this.lang = lang)
    this.helper.serviceArr.subscribe(arr => this.serviceArr = arr)
  }


}
