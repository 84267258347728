import { LoaderService } from './service/loader.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from './service/helper.service';
import { ContactService } from './service/contact.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { routingAnimation } from './router.animations';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routingAnimation],
})
export class AppComponent implements OnInit {
  title = 'kw';
  HomeArr
  constructor(private helper:HelperService,
    public loaderService: LoaderService, private titleService:Title, private toastr:ToastrService, private router: Router, public translate: TranslateService, private contactService:ContactService) {}
  ngOnInit() {

    this.loaderService.isLoading.next(true);
    this.helper.get();
    this.helper.getService();

    this.helper.HomeArr.subscribe(arr =>{ this.HomeArr = arr;  this.titleService.setTitle( this.HomeArr.data?.contactDetails?.contactName);})

    const currentLang = localStorage.getItem('lang');
    if (!currentLang) {
      localStorage.setItem('lang', 'en');
      this.translate.use('en');
    }
    this.langChanged(currentLang);
    this.translate.use(currentLang);
    this.helper.currentLang.next(currentLang);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  langChanged(lang) {
    // const elEn = document.querySelector('#bootstrap-en');
    // const elAr = document.querySelector('#bootstrap-ar');
    if (lang === 'ar') {
      // add bootstrap ar
      // elEn && elEn.remove();

      this.generateLinkElement({
        id: 'bootstrap-en',
        href: 'assets/vendor/bootstrap/css/bootstrap-ar.css',
        dir: 'rtl',
        lang: 'ar',
      });

    } else {
      // en
      // elAr && elAr.remove();
      this.generateLinkElement({
        id: 'bootstrap-en',
        href: 'assets/vendor/bootstrap/css/bootstrap.css',
        dir: 'ltr',
        lang: 'en',
      });
    }
  }
  generateLinkElement(props) {
    const el = document.createElement('link');
    const htmlEl = document.getElementsByTagName('html')[0];
    el.rel = 'stylesheet';
    el.href = props.href;
    el.id = props.id;
    document.head.prepend(el);
    htmlEl.setAttribute('dir', props.dir);
    htmlEl.setAttribute('lang', props.lang);
    this.loaderService.isLoading.next(false);
  }




}
