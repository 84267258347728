import { Router } from '@angular/router';
import { HelperService } from './../service/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  code:string;
  HomeArr:any = {};
  lang:string = 'en'
  constructor(private helper:HelperService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.helper.currentLang.subscribe(lang => this.lang = lang)
    this.helper.HomeArr.subscribe(arr => this.HomeArr = arr)
  }


  send(){
    if(this.code){
      this.router.navigate(["/tracking/" + this.code]);
    }
  }

}
