<ul class="p-0 social-list">
  <li *ngIf="HomeArr.data?.socialLinks?.whatsapp">
    <a [attr.href]="HomeArr.data?.socialLinks?.whatsapp" target="_blank">
      <img src="../../assets/images/icon/1.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.viper">
    <a [attr.href]="HomeArr.data?.socialLinks?.viper" target="_blank">
      <img src="../../assets/images/icon/2.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.skype">
    <a [attr.href]="HomeArr.data?.socialLinks?.skype" target="_blank">
      <img src="../../assets/images/icon/3.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.facebook">
    <a [attr.href]="HomeArr.data?.socialLinks?.facebook" target="_blank">
      <img src="../../assets/images/icon/4.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.vk">
    <a [attr.href]="HomeArr.data?.socialLinks?.vk" target="_blank">
      <img src="../../assets/images/icon/5.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.twitter">
    <a [attr.href]="HomeArr.data?.socialLinks?.twitter" target="_blank">
      <img src="../../assets/images/icon/6.png" alt="" />
    </a>
  </li>
  <li *ngIf="HomeArr.data?.socialLinks?.instagram">
    <a [attr.href]="HomeArr.data?.socialLinks?.instagram" target="_blank">
      <img src="../../assets/images/icon/7.png" alt="" />
    </a>
  </li>
</ul>
