<section class="service-page globle-page dontPrint" *ngIf="print"></section>

<section class="home-service globle-border tracking-section" #screen>
  <div class="container page-body-wrapper">
    <h4 style="
    overflow: hidden;
" class="dontPrint">{{ "track" | translate }}</h4>

    <div class="d-lg-flex justify-content-between mb-5 dontPrint">
      <div class="d-md-flex">
        <div class="form-group mr-md-4 flex-grow-1">
          <input class="form-control" [(ngModel)]="code" placeholder="{{ 'input_home' | translate }}" />
        </div>
        <!-- /form-group -->
        <button class="custom-btn track-btn" (click)="send()">
          {{ "Track" | translate }}
        </button>
      </div>

      <div class="d-lg-block d-flex justify-content-end">
        <button class="
            custom-btn
            light
            d-flex
            justify-content-center
            align-items-center
            print
          " (click)="onPrint('sectionToPrint')">
          <!-- downloadImage();  -->
          {{ "print" | translate }}
          <img src="../../assets/images/icon/print.svg" class="ml-3" />
        </button>
      </div>
    </div>
    <div id="sectionToPrint">
      <div class="row m-0" *ngIf="trackingArr.length > 0">
        <div class="col-lg-3 col-md-4 widget">
          <p>{{ "from" | translate }}</p>
          <h5>{{ trackingArr[0].origin }}</h5>
        </div>
        <!-- /col -->
        <div class="col-lg-3 col-md-4 widget">
          <p>{{ "to" | translate }}</p>
          <h5>{{ trackingArr[0].destination }}</h5>
        </div>
        <!-- /col -->
        <div class="col-lg-6 col-md-4 widget">
          <p>{{ "Transport_Document_Number" | translate }}</p>
          <h5>{{ trackingArr[0].code }}</h5>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->

      <div class="mt-5" *ngIf="trackingArr.length > 0">
        <mat-accordion>
          <mat-expansion-panel class="mb-3" [expanded]="i == 0" *ngFor="let item of trackingArr[0].containers; let i = index">
            <mat-expansion-panel-header>
              <div class="row w-100">
                <div class="col-lg-3 col-md-6">
                  <div class="d-flex">
                    <div class="icon" class="mr-2">
                      <img src="../../assets/images/icon/icon-1.svg" />
                    </div>
                    <!-- /icon -->
                    <div class="content">
                      <p>{{ "Container_Details" | translate }}</p>
                      <p class="mb-0 mt-0">
                        <b>{{ item.code }}</b>
                      </p>
                    </div>
                    <!-- /content -->
                  </div>
                  <!-- /d-flex -->
                </div>
                <!-- /col -->
                <div class="col-lg-3 col-md-6">
                  <div class="d-flex">
                    <div class="icon" class="mr-2">
                      <img src="../../assets/images/icon/icon-2.svg" />
                    </div>
                    <!-- /icon -->
                    <div class="content">
                      <p>{{ "Container_Type_Size" | translate }}</p>
                      <p class="mb-0 mt-0">
                        <b>{{ item.type_size_for_display }}</b>
                      </p>
                    </div>
                    <!-- /content -->
                  </div>
                  <!-- /d-flex -->
                </div>
                <!-- /col -->
                <div class="col-lg-3 col-md-6">
                  <div class="d-flex">
                    <div class="icon" class="mr-2">
                      <img src="../../assets/images/icon/icon-3.svg" />
                    </div>
                    <!-- /icon -->
                    <div class="content">
                      <p>{{ "Arrival_Date_And_Time" | translate }}</p>
                      <p class="mb-0 mt-0">
                        <b>
                          {{ item.arrival_date.split(" ")[0] }}
                          <br />
                          {{ item.arrival_date.split(" ")[1] }}
                        </b>
                      </p>
                    </div>
                    <!-- /content -->
                  </div>
                  <!-- /d-flex -->
                </div>
                <!-- /col -->
                <div class="col-lg-3 col-md-6">
                  <div class="d-flex">
                    <div class="icon" class="mr-2">
                      <img src="../../assets/images/icon/icon-4.svg" />
                    </div>
                    <!-- /icon -->
                    <div class="content">
                      <p>{{ "Last_Location" | translate }}</p>
                      <p class="mb-0 mt-0">
                        <b innerHTML=" {{ item.last_location }}"> </b>
                      </p>
                    </div>
                    <!-- /content -->
                  </div>
                  <!-- /d-flex -->
                </div>
                <!-- /col -->
              </div>
              <!-- /row -->
            </mat-expansion-panel-header>

            <div class="content-wrapper">
              <div class="content" *ngFor="let itm of item.details">
                <div class="row">
                  <div class="col-lg-6">
                    <p class="mb-3">{{ "Location" | translate }}</p>
                    <p>
                      {{ itm.last_location_for_display }}
                    </p>
                    <ng-container *ngIf="itm.related_to_current_location.length > 0">
                      <p *ngFor="let relate of itm.related_to_current_location" class="mt-4">
                        {{ relate.last_location_for_display }}
                      </p>
                    </ng-container>
                  </div>
                  <!-- /col -->
                  <div class="col-lg-3">
                    <p class="mb-3">{{ "Date_time" | translate }}</p>

                    <p class="circal">
                      {{ itm.status_date.split(" ")[0] }}
                      <br />
                      {{ itm.status_date.split(" ")[1] }}
                    </p>

                    <ng-container *ngIf="itm.related_to_current_location.length > 0">
                      <p class="circal mt-4" *ngFor="let relate of itm.related_to_current_location">
                        {{ relate.status_date.split(" ")[0] }}
                        <br />
                        {{ relate.status_date.split(" ")[1] }}
                      </p>
                    </ng-container>
                  </div>
                  <!-- /col -->
                  <div class="col-lg-3">
                    <p class="mb-3">{{ "Activity" | translate }}</p>
                    <p>
                      <b>{{ itm.shipping_method_text }}

                        <br *ngIf="itm.shipping_method_text" />
                        {{ itm.status_brief }}
                      </b>
                      <br />
                      <img width="35" [src]="
                          itm.shipping_method == 1
                            ? ' ../../assets/images/icon/icon-8.svg'
                            : itm.shipping_method == 2
                            ? '../../assets/images/icon/icon-6.svg'
                            : ' ../../assets/images/icon/icon-5.svg'
                        " class="mt-2" />
                    </p>

                    <ng-container *ngIf="itm.related_to_current_location.length > 0">
                      <p *ngFor="let relate of itm.related_to_current_location" class="mt-4">
                        <b>{{
                          relate.shipping_method_text
                            ? relate.shipping_method_text
                            : "Gate In"
                        }}</b>

                        <br />
                        <img width="35" [src]="
                            relate.shipping_method == 1
                              ? ' ../../assets/images/icon/icon-8.svg'
                              : relate.shipping_method == 2
                              ? '../../assets/images/icon/icon-6.svg'
                              : ' ../../assets/images/icon/icon-5.svg'
                          " class="mt-2" />
                      </p>
                    </ng-container>
                  </div>
                  <!-- /col -->
                </div>
                <!-- /row -->
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <h4 class="text-center my-5" style="color: #fff; font-weight: bold" *ngIf="trackingArr.length == 0 && show">
      {{ "NO_result" | translate }}
    </h4>
  </div>
</section>
<a #downloadLink></a>
