import { environment } from './../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  sendContact(body){
    return this.http.post(`${this.apiUrl}engine/contactUs`, body).toPromise();
  }
  sendQuots(body){
    return this.http.post(`${this.apiUrl}engine/pricingRequests`, body).toPromise();
  }

  get(){
    return this.http.get(`${this.apiUrl}engine`).toPromise();
  }

  getservice(){
    return this.http.get(`${this.apiUrl}engine/services`).toPromise();
  }

  getTrack(body){
    return this.http.post(`${this.apiUrl}engine/track`, body).toPromise();
  }
}
