import { HelperService } from './../service/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {


  HomeArr:any ;
  lang:string = 'en'
  constructor(private helper:HelperService) { }

  ngOnInit(): void {
    this.helper.HomeArr.subscribe(arr => this.HomeArr = arr)
    this.helper.currentLang.subscribe(lang => this.lang = lang)

  }

}

export interface IContactList{
  date:{
    contactDetails:{
      contactName:'',
      address:'',
      email:'',
      mobiles:[],
      website:''
    }
  }
}
