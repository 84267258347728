<footer class="dontPrint">
  <div class="container page-body-wrapper">
    <div class="row">
      <div class="col-lg-4 align-self-center">
        <div class="logo-footer">
          <img src="../../assets/images/logo.png" alt="Al Wael" />
        </div>
        <!-- /logo-footer -->
      </div>
      <!-- /col -->
      <div class="col-lg-3">
        <div class="custom-p-footer">
          <div class="title">
            <h4>{{ "SITE_MAP" | translate }}</h4>
          </div>
          <div class="footer-content">
            <ul class="p-0 link-list">
              <li>
                <a [routerLink]="['/services']">{{ "Services" | translate }}</a>
              </li>
              <li>
                <a [routerLink]="['/tracking']">{{ "Tracking" | translate }}</a>
              </li>
              <li>
                <a [routerLink]="['/quotes']">{{ "Quotes" | translate }}</a>
              </li>
              <li>
                <a [routerLink]="['/blog']">{{ "Blog" | translate }}</a>
              </li>
              <li>
                <a class="mb-0" [routerLink]="['/contact']">{{ "Contact_Us" | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /col -->

      <!-- <div class="col-lg-3">
        <div class="title">
          <h4>{{ "social" | translate }}</h4>
        </div>
        <div class="footer-content">
          <app-socail-links></app-socail-links>
        </div>
      </div> -->
      <!-- /col -->
      <div class="col-lg-5">
        <div class="title">
          <h4>{{ "contact" | translate }}</h4>
        </div>
        <app-contact-list></app-contact-list>
      </div>
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
</footer>

<div class="footer-bottom">
  <p>{{ "copyright" | translate }}</p>
</div>
