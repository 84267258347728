<div class="container page-body-wrapper dontPrint">
  <nav>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div class="logo cursor-pointer" id="brand" [routerLink]="['/home']">
              <img src="../../assets/images/logo.png" alt="Al Awael" />
            </div>
            <!-- /logo -->
            <div>
              <div class="lang d-lg-none d-md-flex flex-column align-items-end mt-3">
                <p class="mb-2 font-weight-bold lang-text" style="font-size: 1.5rem;">
                  {{ "choose_a_language" | translate  }}
                </p>
                <div class="d-flex">
                  <img src="../../assets/images/en.png" alt="en" class="cursor-pointer flag" (click)="changeLanguage('en')" />
                  <img src="../../assets/images/fr.png" alt="en" class="mx-3 cursor-pointer flag" (click)="changeLanguage('fr')" />
                  <img src="../../assets/images/ar.png" class=" flag cursor-pointer" alt="ar" (click)="changeLanguage('ar')" />
                </div>
              </div>
              <div class="icon-bar cursor-pointer" (click)="toggleNavbar()">
                <span [ngClass]="{ active: toggle }"></span>
              </div>
              <div class="menu">
                <div class="">
                  <div>
                    <ul [ngClass]="{ active: toggle }" [class.frClass]="lang == 'fr'">
                      <li>
                        <a [routerLink]="['/home']">
                          {{ "Home" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/services']">{{ "Services" | translate }}
                        </a>
                      </li>

                      <li>
                        <a [routerLink]="['/tracking']">
                          {{ "Tracking" | translate }}
                        </a>
                      </li>

                      <li>
                        <a [routerLink]="['/quotes']">
                          {{ "Quotes" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/blog']">
                          {{ "Blog" | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/contact']">
                          {{ "Contact_Us" | translate }}
                        </a>
                      </li>
                      <!-- <li class="d-none d-lg-inline-block">
                        <span style="color: #FFF;  font-size: 1.5rem;">
                          {{ "choose_a_language" | translate  }}

                        </span>
                        <ul class="sub-lang mt-0">
                          <li>
                            <a (click)="changeLanguage('en')">
                              EN
                            </a>
                          </li>
                          <li>
                            <a (click)="changeLanguage('fr')">
                              FR
                            </a>
                          </li>
                          <li>
                            <a (click)="changeLanguage('ar')">
                              AR
                            </a>
                          </li>
                        </ul>
                      </li> -->
                      <li style="cursor: pointer;" class="d-none d-lg-inline-block showdiv">
                        <a>
                          {{ "language" | translate  }}
                        </a>
                        <div class="drop-lang">

                          <a (click)="changeLanguage('en')" style="text-align: center;">
                            English
                          </a>
                          <a (click)="changeLanguage('fr')" style="text-align: center;">
                            français
                          </a>

                          <a (click)="changeLanguage('ar')" style="text-align: center;">
                            العربية
                          </a>

                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /container -->
              </div>
              <!-- /menu -->
            </div>
          </div>
          <!-- /d-flex -->
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </nav>
</div>
