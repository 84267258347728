import { HelperService } from './../service/helper.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socail-links',
  templateUrl: './socail-links.component.html',
  styleUrls: ['./socail-links.component.scss']
})
export class SocailLinksComponent implements OnInit {

  HomeArr:any;
  lang:string = 'en'
  constructor(private helper:HelperService) { }

  ngOnInit(): void {
    this.helper.HomeArr.subscribe(arr => this.HomeArr = arr)
    this.helper.currentLang.subscribe(lang => this.lang = lang)

  }

}
