import { ContactService } from './../service/contact.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from './../service/helper.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import html2canvas from 'html2canvas';
import { NgxCaptureService } from 'ngx-capture';
import { tap } from "rxjs/operators";

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  panelOpenState = false;
  code: string;
  trackingArr =[];
  img = "";
  print = false;
  show = false;
  // @ViewChild('screen', { static: true }) screen: any;

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private helper: HelperService,
    private toastr: ToastrService,
    private captureService: NgxCaptureService,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.print = true;

    this.code = this.activatedRoute.snapshot.params.id;
    if (this.code) {
      this.send()
    }

  }

  onPrint(divName) {window.print();
}

  downloadImage(){
    this.print = false;
    this.captureService
    .getImage(this.screen.nativeElement, true)
    .pipe(
      tap(img => {
        // this.img = img;
    this.print = true;
      this.downloadLink.nativeElement.href = img;
      this.downloadLink.nativeElement.download = 'Tracking.png';
      this.downloadLink.nativeElement.click();
      })
    )
    .subscribe();
    // html2canvas(this.screen.nativeElement).then(canvas => {
    //   this.canvas.nativeElement.src = canvas.toDataURL();
    //   this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
    //   this.downloadLink.nativeElement.download = 'marble-diagram.png';
    //   this.downloadLink.nativeElement.click();
    // });
  }
  async send() {
    try {
      this.trackingArr=[];
      const formData = this.helper.jsonToFormData({code: this.code})
      const res:any = await this.contactService.getTrack(formData);
      if(res.status.code == 200){
        this.trackingArr = res.data.policy;
        this.trackingArr[0].containers.map(el => {
          el.details = el.details.sort((a,b) => (a.status_date > b.status_date) ? 1 : ((b.status_date > a.status_date) ? -1 : 0)) // sort by time
        })
        this.show = true
      }
    } catch (err) {
      console.log(err);
    }
  }
}
