<section class="home-page globle-page">
  <div class="container page-body-wrapper">
    <div class="row m-0 align-items-end">
      <div class="col-lg-5 align-self-center home-title-section">
        <div class="dec">
          <!-- <h1>{{ "title_home" | translate }}</h1>
          <p>
            {{ "dec_home" | translate }}
          </p> -->
          <h1 innerHTML="{{
              lang == 'en'
                ? HomeArr.data?.firstSection[0]?.title_en
                : (lang == 'fr' ?  HomeArr.data?.firstSection[0]?.title_fr :  HomeArr.data?.firstSection[0]?.title_ar)
            }}"></h1>
          <p innerHTML="{{
              lang == 'en'
                ? HomeArr.data?.firstSection[0]?.description_en
                : (lang == 'fr' ? HomeArr.data?.firstSection[0]?.description_fr : HomeArr.data?.firstSection[0]?.description_ar)
            }}"></p>
        </div>
      </div>
      <!-- /col -->
      <div class="col-lg-4 offset-lg-3">
        <div class="my-5 py-5"></div>
        <div class="track-card text-center mt-5 ">
          <img src="../../assets/images/track.png" alt="" />
          <!-- <h2>
            {{ "Tracking_Shippment" | translate }}
          </h2> -->

          <input type="search" [(ngModel)]="code" placeholder="{{ 'input_home' | translate }}" />

          <button class="custom-btn" (click)="send()">
            {{ "Track" | translate }}
          </button>
        </div>
        <!-- /track-card -->
      </div>
      <!-- /col -->
    </div>
    <!-- /row -->
  </div>
</section>

<section class="home-service globle-border" style="top: 0;margin-bottom: 0;margin-top: -10px;">
  <div class="container page-body-wrapper">
    <div class="row m-0 static-section align-items-center">
      <div class="col-12">
        <div *ngIf="lang == 'en'">
          <h4>
            Land International Transport Services
          </h4>
        </div>
        <div *ngIf="lang == 'fr'">
          <h4>
            Services De Transport Terrestre International
          </h4>
        </div>
        <div *ngIf="lang == 'ar'">
          <h4>
            خدمات النقل البري الدولي
          </h4>
        </div>
      </div>
      <div class="col-lg-12">
        <div *ngIf="lang == 'en'">
          <p>
            Provides land freight services to many neighboring countries <br>
            Where we have a sufficient number of refrigerated and dry containers <br>
            We offer the best prices and the best offers. <br>
            We offer a wide variety of land freight and transportation services. <br>
            This includes container transportation, trailers, <br>
            Closed cars, 5 tons, 7 tons, 10 tons, <br>
            And other goods movements for the project as well. <br>
            We have experience in cargo carriers <br>
            <!-- chemical cargo carriers, <br> -->
            And our teams are well-equipped to handle land freight and loading services
            <br>
            Best rates for international road transport <br>
            The company has cars for land freight trailers <br>
            <!-- With dimensions starting from 12,50 m up to 15 m <br>
            The width is 2,50 m, and the sides are 2,50 m high <br>
            The maximum load capacity is 30 tons <br>
            There are also containers with dimensions starting from 12 m <br>
            And up to 19 m, width 2.60 m, and sides height 3 m <br>
            There are land refrigerators, the length of the refrigerator is 12,50 m <br>
            13,60 m, width 2,45 m, and payload up to 30 tons <br>
            We have tractors with a length of 15 m and a load capacity of 120 tons
            <br> <br>
            International land freight services for agricultural crops <br>
            The company's refrigerated cars go to export stations <br>
            Agricultural crops to receive the shipment <br>
            And adjusting the cooling degrees necessary for the type of goods <br>
            Then the cars go to the destination country to warehouses <br>
            The customer is directly free of customs duties, approvals, etc <br>
            Door to door car shipping services <br> -->
          </p>

        </div>
        <div *ngIf="lang == 'fr'">

          <p>
            Fournit des services de fret terrestre à de nombreux pays voisins <br>
            Où nous avons un nombre suffisant de conteneurs réfrigérés et secs <br>
            Nous offrons les meilleurs prix et les meilleures offres. <br>
            Nous offrons une grande variété de services de fret et de transport terrestres. <br>
            Cela comprend le transport de conteneurs, les remorques, <br>
            Voitures fermées, 5 tonnes, 7 tonnes, 10 tonnes, <br>
            Et d'autres mouvements de stock pour le projet également. <br>
            Nous avons de l'expérience dans les transporteurs de fret <br>
            <!-- transporteurs de produits chimiques, <br> -->
            Et nos équipes sont bien équipées pour gérer les services de fret terrestre et de chargement
            <br>
            <!-- <br> -->
            Meilleurs tarifs pour le transport routier international <br>
            L'entreprise dispose de voitures pour les remorques de fret terrestre <br>
            <!-- Avec des dimensions à partir de 12,50 m jusqu'à 15 m <br>
            La largeur est de 2,50 m, et les côtés sont de 2,50 m de haut <br>
            La capacité de charge maximale est de 30 tonnes <br>
            Il existe également des conteneurs avec des dimensions à partir de 12 m <br>
            Et jusqu'à 19 m, largeur 2,60 m, et côtés hauteur 3 m <br>
            Il existe des réfrigérateurs terrestres, la longueur du réfrigérateur est de 12,50 m <br>
            13,60 m, largeur 2,45 m et charge utile jusqu'à 30 tonnes <br>
            Nous avons des tracteurs d'une longueur de 15 m et d'une capacité de charge de 120 tonnes<br><br>
            Services internationaux de fret terrestre pour les cultures agricoles <br>
            Les voitures frigorifiques de l'entreprise vont aux stations d'exportation <br>
            Cultures agricoles pour recevoir l'expédition <br>
            Et ajuster les degrés de refroidissement nécessaires pour le type de marchandises <br>
            Ensuite, les voitures se rendent dans le pays de destination vers les entrepôts <br>
            Le client est directement exempt de droits de douane, d'agréments, etc. <br>
            Services d'expédition de voiture porte à porte <br> -->
          </p>

        </div>
        <div *ngIf="lang == 'ar'">

          <p>
            نقدم خدمات الشحن البري للعديد من الدول الجوار <br>
            حيث يتوفر لدينا العدد الكافي من الحاويات المبردة والجافة <br>
            كما نقدم أفضل الأسعار وأفضل العروض <br>
            نحن نقدم مجموعة متنوعة من خدمات الشحن البري والنقل <br>
            وهذا يشمل نقل الحاويات ، المقطورات <br>
            <!-- سيارات مغلقة ، 5 طن ، 7 طن ، 10 طن <br> -->
            وغيرها من شاحنات البضائع <br>
            لدينا خبرة في مجال ناقلات البضائع <br>
            وفرقنا مجهزة تجهيزًا جيدًا للتعامل مع الشحن البري وخدمات التحميل
            <br>

            أفضل الأسعار للنقل البري الدولي <br>
            حيث تمتلك الشركة سيارات لمقطورات الشحن البري <br>
            <!-- بأبعاد تبدأ من 12.50 م حتى 15 م <br>
            العرض 2،50 م والجوانب بارتفاع 2،50 م <br>
            الحمولة القصوى 3 <br>
            كما يوجد حاويات بأبعاد تبدأ من 12 م <br>
            وحتى 19 م وعرض 2.60 م وارتفاع الضلع 3 م <br>
            يوجد ثلاجات أرضية طول الثلاجة 12.50 م <br>
            13،60 م وعرض 2،45 م وحمولة تصل إلى 30 طنا <br>
            لدينا جرارات بطول 15 م و حمولة 120 طن
            <br>
            <br>
            خدمات الشحن البري الدولي للمحاصيل الزراعية<br>
            تذهب سيارات الشركة المبردة لمحطات تصدير<br>
            المحاصيل الزراعية لاستلام الشحنة<br>
            وضبط درجات التبريد اللازمة لنوع البضاعة<br>
            ثم تذهب السيارات إلى بلد المقصد حيث المستودعات<br>
            الخاصة بالعميل وتسليم البضائع خالية من الرسوم الجمركية والموافقات وغيرها مباشرة<br>
            خدمات شحن السيارات من الباب الى الباب<br> -->
          </p>

        </div>
      </div>
      <!-- <div class="col-lg-6">
        <img src="assets/images/main.png" style="width: 100%;">
      </div> -->
    </div>
    <!-- <div class="row m-0">
      <div class="col-lg-4" *ngFor="let item of HomeArr.data?.sevicesSection">
        <div class="icon">
          <img [src]="
              item.id == 4
                ? '../../assets/images/icon-1.png'
                : item.id == 5
                ? '../../assets/images/icon-2.png'
                : '../../assets/images/icon-3.png'
            " alt="" />
        </div>
        <h3 innerHTML="{{ lang == 'en' ? item.title_en :  (lang == 'fr' ? item.title_fr :  item.title_ar)}}"></h3>
        <p innerHTML="{{
            lang == 'en' ? item.description_en :  (lang == 'fr' ? item.description_fr :  item.description_ar)}}"></p>
      </div>

    </div> -->
    <!-- /row -->
  </div>
  <!-- /container -->
</section>

<!-- /home-service -->
