import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (req.url.includes('/api/login')) {
    //   return next.handle(req);
    // }
    const authReq = req.clone({
      setHeaders: {
        APIKEY:"f450c1e62a74ad454a4a1eb86abe2d2d" ,
      }
    });
    return next.handle(authReq);
  }
}
